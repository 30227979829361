import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { InvoiceService } from '@service/invoice.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceGuard  {
  constructor(
    private readonly router: Router,
    private readonly invoiceService: InvoiceService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hash = route.queryParams['invoiceid'];

    if (hash) {
      this.invoiceService.findInvoiceOrderByHash(hash).subscribe(invoiceOrder => {
        if (invoiceOrder) {
          this.invoiceService.handleInvoiceOrder(invoiceOrder, hash);
          this.router.navigate(['/order', 'overview'], { queryParams: route.queryParams });
        }
      });
    }

    return true;
  }

}
